import React from "react";
import "./App.css";
import { AuthProvider } from "./Auth";
import { Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { renderRoutes } from "./Routes";
import { Col, Row } from "antd";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Suspense fallback={<div />}>
          {renderRoutes.map(([key, value]) => (
            <value.routeComponent
              key={key}
              path={value.path}
              exact={value?.exact}
            >
              <value.component />
            </value.routeComponent>
          ))}
        </Suspense>
      </Switch>
    </BrowserRouter>
  );
};

function App() {
  return (
    <Row>
      <Col span={20} offset={2}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </Col>
    </Row>
  );
}

export default App;
