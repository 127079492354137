import axios from "axios";
import { Auth } from "aws-amplify";

export const requestMagicLink = async (email: string) => {
  const res = await axios.post(process.env.REACT_APP_AUTH_API_ENDPOINT || "", {
    email,
  });
  return res.data;
};

async function getAccessToken() {
  const currentSession = await Auth.currentSession();
  return currentSession.getAccessToken().getJwtToken();
}

type HeaderType = {
  Accept: string;
  Authorization: string;
};

type OptionsType = {
  method: string;
  headers: HeaderType;
  body?: string;
};

async function getOptions(method: string, data?: object) {
  return getAccessToken().then((accessToken) => {
    const options: OptionsType = {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    if (data && method === "POST") {
      options.body = JSON.stringify(data);
    }
    return options;
  });
}

export const apiGet = (operation: string) => {
  return getOptions("GET").then((options) => {
    return fetch(
      process.env.REACT_APP_PRIVATE_API_ENDPOINT + operation,
      options
    ).then((o) => {
      return o.json();
    });
  });
};

export const apiPost = (operation: string, data: any) => {
  return getOptions("POST", data).then((options) => {
    return fetch(
      process.env.REACT_APP_PRIVATE_API_ENDPOINT + operation,
      options
    ).then((o) => {
      return o.json();
    });
  });
};
