import * as React from "react";
import { Route } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export type RouteItem = {
  path: string;
  routePath: (args?: any) => string;
  routeComponent: (props: React.ComponentProps<typeof Route>) => JSX.Element;
  component: any;
  exact?: boolean;
};

export const routes: Record<string, RouteItem> = {
  home: {
    path: "/",
    routePath: () => "/",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Home/Home")),
    exact: true,
  },
  signIn: {
    path: "/signIn",
    routePath: () => "/signIn",
    routeComponent: PublicRoute,
    component: React.lazy(() => import("./pages/SignIn/SignIn")),
  },
  votacion: {
    path: "/votacion/:votacionId",
    routePath: () => "/votacion/:votacionId",
    routeComponent: PrivateRoute,
    component: React.lazy(() => import("./pages/Votacion/ShowVotacion")),
  },
  verify: {
    path: "/verify",
    routePath: () => "/verify",
    routeComponent: PublicRoute,
    component: React.lazy(
      () => import("./pages/VerifyMagicLink/VerifyMagicLink")
    ),
  },
};

export const renderRoutes = Object.entries(routes);
